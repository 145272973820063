// src/components/Contact.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactSection = styled.section`
  min-height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #f39c12;
  margin-bottom: 40px;
  font-family: 'Poppins', sans-serif;
`;

const ContactForm = styled.form`
  background-color: #34495e;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputField = styled.input`
  padding: 15px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  background-color: #fff;
`;

const TextArea = styled.textarea`
  padding: 15px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  background-color: #fff;
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #f39c12;
  color: #fff;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e67e22;
  }
`;

const SocialIcons = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 20px;
`;

const IconLink = styled.a`
  color: white;
  font-size: 2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #f39c12;
  }
`;

const Footer = styled.div`
  margin-top: 50px;
  font-size: 0.9rem;
  text-align: center;
  color: #bdc3c7;
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.open(`mailto:AdyanthGanesh@gmail.com?subject=${formData.subject}&body=${formData.message}%0D%0AFrom: ${formData.name}%0D%0AEmail: ${formData.email}`);
  };

  return (
    <ContactSection id="contact">
      <Title>Contact Me</Title>
      <ContactForm onSubmit={handleSubmit}>
        <InputField
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <InputField
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <InputField
          type="text"
          name="subject"
          placeholder="Subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />
        <TextArea
          name="message"
          placeholder="Compose your message"
          rows="6"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Send Message</SubmitButton>
      </ContactForm>

      <SocialIcons>
        <IconLink href="https://linkedin.com" target="_blank">
          <FontAwesomeIcon icon={faLinkedin} />
        </IconLink>
        <IconLink href="https://github.com" target="_blank">
          <FontAwesomeIcon icon={faGithub} />
        </IconLink>
        <IconLink href="https://twitter.com" target="_blank">
          <FontAwesomeIcon icon={faTwitter} />
        </IconLink>
        <IconLink href="mailto:AdyanthGanesh@gmail.com" target="_blank">
          <FontAwesomeIcon icon={faEnvelope} />
        </IconLink>
      </SocialIcons>

      <Footer>
        &copy; {new Date().getFullYear()} Adyanth Ganesh. All rights reserved.
      </Footer>
    </ContactSection>
  );
};

export default Contact;
