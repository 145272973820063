import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython, faJs, faReact, faNodeJs, faAws } from '@fortawesome/free-brands-svg-icons';
import { faCode, faAward, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

const SkillsSection = styled.section`
  min-height: 100vh;
  padding: 100px 20px;
  background-color: #eef4f9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #2980b9;
  font-weight: 600;
  margin-bottom: 50px;
  font-family: 'Poppins', sans-serif;
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
`;

const SkillCard = styled.div`
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const SkillIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: #3498db;
  margin-bottom: 20px;
`;

const SkillName = styled.h3`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
`;

const HonorsSection = styled.section`
  padding: 50px 20px;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HonorCard = styled(SkillCard)`
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: center;
`;

const HonorTitle = styled.h3`
  font-size: 1.5rem;
  color: #2980b9;
  margin-bottom: 10px;
`;

const HonorDescription = styled.p`
  font-size: 1rem;
  color: #555;
`;

const Skill = ({ icon, name }) => (
  <SkillCard>
    <SkillIcon icon={icon} />
    <SkillName>{name}</SkillName>
  </SkillCard>
);

const SkillsAndHonors = () => {
  return (
    <>
      <SkillsSection id="skills">
        <Title>Skills & Technologies</Title>
        <SkillsGrid>
          <Skill icon={faPython} name="Python, C++, Java" />
          <Skill icon={faJs} name="JavaScript & R" />
          <Skill icon={faReact} name="React & React Native" />
          <Skill icon={faNodeJs} name="Node.js & Flask" />
          <Skill icon={faAws} name="AWS & Cloud Technologies" />
          <Skill icon={faProjectDiagram} name="Machine Learning & AI" />
          <Skill icon={faCode} name="Algorithm Development & Data Science" />
        </SkillsGrid>
      </SkillsSection>

      <HonorsSection id="honors">
        <Title>Honors & Achievements</Title>
        <SkillsGrid>
          <HonorCard>
            <FontAwesomeIcon icon={faAward} size="3x" color="#f39c12" />
            <HonorTitle>USA Computing Olympiad Silver Division</HonorTitle>
            <HonorDescription>Perfect score achieved in 2024 competition.</HonorDescription>
          </HonorCard>

          <HonorCard>
            <FontAwesomeIcon icon={faAward} size="3x" color="#f39c12" />
            <HonorTitle>MIT Hackathon - Top 16</HonorTitle>
            <HonorDescription>Shinkansen Train Predictive Analysis, 0.7% away from 1st place.</HonorDescription>
          </HonorCard>

          <HonorCard>
            <FontAwesomeIcon icon={faAward} size="3x" color="#f39c12" />
            <HonorTitle>National Future Problem Solvers - 2nd Place</HonorTitle>
            <HonorDescription>
              Recognized for mental health initiatives and data analysis.
            </HonorDescription>
          </HonorCard>

          <HonorCard>
            <FontAwesomeIcon icon={faAward} size="3x" color="#f39c12" />
            <HonorTitle>Dean's List & Academic Excellence</HonorTitle>
            <HonorDescription>Mercer County College, GPA: 4.0/4.0</HonorDescription>
          </HonorCard>

          <HonorCard>
            <FontAwesomeIcon icon={faAward} size="3x" color="#f39c12" />
            <HonorTitle>AP Scholar & Music Awards</HonorTitle>
            <HonorDescription>Recognized for academic and artistic achievements.</HonorDescription>
          </HonorCard>
        </SkillsGrid>
      </HonorsSection>
    </>
  );
};

export default SkillsAndHonors;
